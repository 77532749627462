"use strict";

// Import main stylesheet
import "./scss/All.scss";

import stickyHeader from "./js/sticky-header";
import mobileMenu from "./js/mobile-menu";
import cookieNotification from "./js/components/cookie-notification";
import lazyLoadInit from "./js/lazy-load";
import initForms from "./js/init-contact-form.js";
import { formSubmit, serialize } from "./js/form-submit";
import {
  mainFormValidation,
  reservationFormValidation,
} from "./js/input-validation";
import { productSlider } from "./js/sliders";
import {
  setOrder,
  getOrder,
  takeInputValuesOrderForm,
} from "./js/ordering-cards";
import initLightBox from "./js/init-glightbox";
import unSubscribe from "./js/unsubscribe";

(function () {
  var me = {};

  me.init = function () {
    stickyHeader();
    mobileMenu();
    lazyLoadInit();
    // priceSlider();
    productSlider();
    setOrder();
    getOrder();
    takeInputValuesOrderForm();
    initForms();
    mainFormValidation();
    formSubmit();
    reservationFormValidation();
    initLightBox();
    cookieNotification();
    unSubscribe();
  };

  // document.addEventListener("DOMContentLoaded", me.init);
  me.init();
})();
