var stickyHeader = function () {
  const headerElement = document.querySelector("#header"),
    observerElement = document.querySelector("#int-observer"),
    stickyHero = document.querySelector(".homepage-hero-wrapper");

  if (!observerElement) {
    return;
  }
  const handler = (entries) => {
    if (!entries[0].isIntersecting) {
      headerElement.classList.add("sticky");
      if (stickyHero) {
        stickyHero.classList.add("sticky-hero");
      }
    } else {
      headerElement.classList.remove("sticky");
      if (stickyHero) {
        stickyHero.classList.remove("sticky-hero");
      }
    }
  };

  // create the observer
  const observer = new window.IntersectionObserver(handler);
  // give the observer some dom nodes to keep an eye on
  observerElement && observer.observe(observerElement);
};

export default stickyHeader;
