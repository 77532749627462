const unSubscribe = () => {
  let unsubscribeBtn = document.getElementById("unsubscribeBtn");

  if (!unsubscribeBtn) {
    return;
  }

  let params = new URL(document.location).searchParams;
  let emailKey = params.get("emailKey");

  if (!emailKey) {
    return;
  }

  var page = "/unsubscribed";
  orderSearchParams = `${page}${window.location.search}`;
  unsubscribeBtn.setAttribute(
    "href",
    `${window.location.origin}${orderSearchParams}`
  );
};

export default unSubscribe;
