import GLightbox from "glightbox";

const initLightBox = function () {
  let productPage = document.getElementById('ProductsPage');
  if (productPage){
    const lightbox = GLightbox({
      touchNavigation: true,
      closeButton: true,
    });
  }
};

export default initLightBox;
