const _sameDay = (day1, day2) => {
  if (
    day1.getFullYear() === day2.getFullYear() &&
    day1.getMonth() === day2.getMonth() &&
    day1.getDate() === day2.getDate()
  ) {
    return true;
  } else {
    return false;
  }
};

// Checking if is chosen today's date

const isChosenDateToday = () => {
  let formMainDate = document.querySelector(".main-form-date");

  if (!formMainDate) {
    return;
  }

  let chosenDays = formMainDate.value.substring(0, 2);
  let chosenMonths = parseInt(formMainDate.value.substring(3, 5)) - 1;
  let chosenYears = formMainDate.value.substring(6, 10);

  const chosenDate = new Date(chosenYears, chosenMonths, chosenDays);
  const todaysDate = new Date();

  if (_sameDay(todaysDate, chosenDate) === true) {
    return true;
  } else {
    return false;
  }
};

// Checking if is chosen past time

const isChosenPastTime = () => {
  let formMainTime = document.querySelector(".main-form-time");

  if (!formMainTime) {
    return;
  }

  let chosenHours = parseInt(formMainTime.value.substring(0, 2));
  let choseMinutes = parseInt(formMainTime.value.substring(3, 5));
  let chosenTimeTotalInMinutes = chosenHours * 60 + choseMinutes;

  let todaysDate = new Date();

  let currentTimeHours = todaysDate.getHours();
  let currentTimeMinutes = todaysDate.getMinutes();
  let currentTimeTotalInMinutes = currentTimeHours * 60 + currentTimeMinutes;

  if (currentTimeTotalInMinutes > chosenTimeTotalInMinutes) {
    return true;
  } else {
    return false;
  }
};

// Checking if time input is filled cor

const mainFormInputTimeListener = () => {
  let formMainTime = document.querySelector(".main-form-time");
  let formMainTimeInvalidFeedback = document.querySelector(
    ".main-form-time-invalid-feedback"
  );

  if (!formMainTime) {
    return;
  }

  formMainTime.addEventListener("change", () => {
    let chosenHours = parseInt(formMainTime.value.substring(0, 2));
    let choseMinutes = parseInt(formMainTime.value.substring(3, 5));
    let chosenTimeTotalInMinutes = chosenHours * 60 + choseMinutes;

    let todaysDate = new Date();

    let currentTimeHours = todaysDate.getHours();
    let currentTimeMinutes = todaysDate.getMinutes();
    let currentTimeTotalInMinutes = currentTimeHours * 60 + currentTimeMinutes;

    if (currentTimeTotalInMinutes > chosenTimeTotalInMinutes) {
      if (isChosenDateToday() === true) {
        formMainTimeInvalidFeedback.classList.add("d-block");
        formMainTime.classList.remove("is-valid");
        formMainTime.classList.add("is-invalid");
      }
    } else {
      formMainTimeInvalidFeedback.classList.remove("d-block");
      formMainTime.classList.remove("is-invalid");
      formMainTime.classList.add("is-valid");
    }
  });
};

// mainFormInputsLIstener

const checkIfTimeInputIsCorrect = () => {
  let formMainTime = document.querySelector(".main-form-time");
  let formMainTimeInvalidFeedback = document.querySelector(
    ".main-form-time-invalid-feedback"
  );
  let checker = false;

  if (formMainTime.value === "") {
    return false;
  } else if (isChosenDateToday() === false) {
    formMainTimeInvalidFeedback.classList.remove("d-block");
    formMainTime.classList.add("is-valid");
    formMainTime.classList.remove("is-invalid");
  } else {
    if (isChosenPastTime() === true) {
      formMainTimeInvalidFeedback.classList.add("d-block");
      formMainTime.classList.remove("is-valid");
      formMainTime.classList.add("is-invalid");
      checker = true;
    }
  }

  if (checker === true) {
    return true;
  }
};

const mainFormInputDateListener = () => {
  let formMainDate = document.querySelector(".main-form-date");
  let formMainTime = document.querySelector(".main-form-time");
  let formMainTimeInvalidFeedback = document.querySelector(
    ".main-form-time-invalid-feedback"
  );
  let formMainDateInvalidFeedback = document.querySelector(
    ".main-form-date-invalid-feedback"
  );

  if (!formMainDate) {
    return;
  }

  formMainDate.addEventListener("change", () => {
    if (!(formMainDate.value === "")) {
      formMainDateInvalidFeedback.classList.remove("d-block");
    }

    if (isChosenDateToday() === false) {
      formMainTimeInvalidFeedback.classList.remove("d-block");
      formMainTime.classList.remove("is-invalid");

      if(formMainTime.value.length > 0) {
        formMainTime.classList.add("is-valid");
        formMainTime.classList.remove("is-invalid");
      }
    } else {
      if (isChosenPastTime() === true) {
        formMainTimeInvalidFeedback.classList.add("d-block");
        formMainTime.classList.remove("is-valid");
        formMainTime.classList.add("is-invalid");
      }
    }
  });
  formMainTime.addEventListener("change", () => {
    if(formMainTime.value.length > 0 && formMainDate.value) {
      formMainTime.classList.add("is-valid");
      formMainTime.classList.remove("is-invalid");
    }
    if (isChosenDateToday() === true && isChosenPastTime() === true) {
      formMainTimeInvalidFeedback.classList.add("d-block");
      formMainTime.classList.remove("is-valid");
      formMainTime.classList.add("is-invalid");
    }

  });

};

// Adding change listener to all inputs

const addInputsChangeListener = () => {
  let inputs = document.querySelectorAll(".main-form .form-control");

  inputs.forEach((input) => {
    if (input.classList.contains("main-form-time")) {
      return;
    }

    input.addEventListener("change", (e) => {
      let isInputValueValid = input.checkValidity();
      if (input.type === "email") {
        let invalidEmailMessage = document.querySelector(".invalid-feedback-email")
        let emptyEmailMessage = document.querySelector(".invalid-feedback-empty")

        if(input.value === "") {
          invalidEmailMessage.classList.add("d-none");
          emptyEmailMessage.classList.remove("d-none");
          input.classList.add("is-invalid");
        }
        else if(!e.target.checkValidity()) {
          emptyEmailMessage.classList.add("d-none");
          invalidEmailMessage.classList.remove("d-none");
        }
      }

      if (isInputValueValid === true) {
        input.classList.add("is-valid");
        input.classList.remove("is-invalid");
      } else {
        input.classList.remove("is-valid");
        input.classList.add("is-invalid");
      }
    });
  });
};

//

const removeValidAndInvalidClasses = () => {
  let validInputs = document.querySelectorAll(".is-valid");
  let invalidInputs = document.querySelectorAll(".is-invalid");
  let invalidFeedbacksLabels = document.querySelectorAll(".invalid-feedback");
  let wasValidatedDivs = document.querySelectorAll(".was-validated");

  validInputs.forEach((input) => {
    input.classList.remove("is-valid");
  });

  invalidInputs.forEach((input) => {
    input.classList.remove("is-invalid");
  });

  invalidFeedbacksLabels.forEach((label) => {
    label.classList.add("d-none");
  });
  wasValidatedDivs.forEach((div) => {
    div.classList.remove("was-validated")
  })
};

const checkIfTimeInputIsNotEmpty = () => {
  let formMainTime = document.querySelector(".main-form-time");

  if (formMainTime.value === "") {
    return false;
  } else {
    return true;
  }
};

const changeTimeAndDateValuesPopUp = (form) => {
  if (form.classList.contains("main-form")) {
    let chosenTime = document.querySelector(".main-form-time").value;
    let chosenDate = document.querySelector(".main-form-date").value;
    let chosenTimeHolder = document.querySelector(".chosen-time");
    let notChosenTimeAndDatePlaceholder = document.querySelector(
      ".not-chosen-time-and-date"
    );
    let atThe = document.querySelector(".at-the");

    if (!chosenDate === "" || !chosenTime === "") {
      chosenTimeHolder.classList.remove("d-none");
      atThe.classList.remove("d-none");
      chosenTimeHolder.innerHTML = `${chosenTime} ${chosenDate}`;
      notChosenTimeAndDatePlaceholder.classList.add("d-none");
    } else {
      atThe.classList.add("d-none");
      notChosenTimeAndDatePlaceholder.classList.remove("d-none");
      chosenTimeHolder.classList.add("d-none");
    }
  }
};

// const checkIfTimeIsChosenAndDateInputIsEmpty = () => {
//   let formMainDate = document.querySelector(".main-form-date");
//   let formMainDateInvalidFeedback = document.querySelector(
//     ".main-form-date-invalid-feedback"
//   );

//   let chosenTime = document.querySelector(".main-form-time").value;
//   let chosenDate = document.querySelector(".main-form-date").value;

//   if (!chosenTime == "" && chosenDate == "") {
//     formMainDate.classList.add("is-invalid");
//     formMainDateInvalidFeedback.classList.add("d-block");
//     return true;
//   } else {
//     return false;
//   }
// };

// Reservation Form Validation

const reservationFormValidation = () => {
  let inputs = document.querySelectorAll(".form-reservation .form-control");

  inputs.forEach((input) => {
    input.addEventListener("change", () => {
      let isInputValueValid = input.checkValidity();

      if (isInputValueValid === true) {
        input.classList.add("is-valid");
        input.classList.remove("is-invalid");
      } else {
        input.classList.remove("is-valid");
        input.classList.add("is-invalid");
      }
    });
  });
};

const mainFormValidation = () => {
  addInputsChangeListener();
  mainFormInputTimeListener();
  mainFormInputDateListener();
};

export {
  mainFormValidation,
  checkIfTimeInputIsCorrect,
  checkIfTimeInputIsNotEmpty,
  isChosenDateToday,
  isChosenPastTime,
  removeValidAndInvalidClasses,
  changeTimeAndDateValuesPopUp,
  reservationFormValidation,
};
