import flatpickr from "flatpickr";

const initForms = () => {
  // Init Flatpickr for Date Input

  const initFlatPickrForDateInput = () => {
    let inputDate = flatpickr(".main-form-date", {
      disable: [
        function (date) {
          // return true to disable
          return date.getDay() === 0 || date.getDay() === 6;
        },
      ],
      locale: {
        firstDayOfWeek: 1, // start week on Monday
      },
      minDate: "today",
      dateFormat: "d.m.Y",
    });
  };

  // Init Flatpickr for Time Input

  const initFlatPickrForTimeInput = () => {
    let inputTime = flatpickr(".main-form-time", {
      disable: [function (date) {}],
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
      minTime: "09:00",
      maxTime: "17:30",
    });
  };

  // const formDateAndTimeUpdater = () => {
  //   let formDate = document.querySelector(".main-form-date");
  //   let formTime = document.querySelector(".main-form-time");
  //   let mainForm = document.querySelector(".main-form");
  //   if (!mainForm) {
  //     return;
  //   }

  //   var date = new Date();

  //   let todaysDate = formatDate(date);
  //   let currentTime = formatTime(date);
  //   formDate.placeholder = "Specify date";
  //   formTime.placeholder = "Specify time";
  // };

  const formatDate = (dateObject) => {
    let date = dateObject;
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) {
      month = `0${month}`;
    }
    if (day < 10) {
      day = `0${day}`;
    }

    let todaysDate = `${day}.${month}.${year}`;

    return todaysDate;
  };

  const formatTime = (dateObject) => {
    let date = dateObject;
    let hours = date.getHours();
    let minutes = date.getMinutes();

    if (hours < 10) {
      hours = `0${hours}`;
    }

    if (minutes < 10) {
      minutes = `0${minutes}`;
    }

    let currentTime = `${hours}:${minutes}`;

    return currentTime;
  };

  const removePlaceholdersDateAndTime = () => {};

  initFlatPickrForDateInput();
  initFlatPickrForTimeInput();
  // formDateAndTimeUpdater();
};

export default initForms;
