var mobileMenu = function () {
  var hamburger = document.querySelector(".hamburger"),
    mobileMenu = document.querySelector(".menu-wrapper"),
    body = document.querySelector("body");
  if (!hamburger) {
    return;
  }

  hamburger.addEventListener("click", (e) => {
    e.preventDefault();

    if (mobileMenu.classList.contains("opened")) {
      mobileMenu.classList.remove("opened");
      hamburger.classList.remove("is-active");
      body.classList.remove("no-scroll");
    } else {
      mobileMenu.classList.add("opened");
      hamburger.classList.add("is-active");
      body.classList.add("no-scroll");
    }
  });
};

export default mobileMenu;
