import Swiper, { Navigation, Thumbs } from "swiper";

// export const priceSlider = () => {
//   var slider = document.querySelector(".price-slider");
//   if (!slider) {
//     return;
//   }

//   var swiper = new Swiper(".price-slider", {
//     // pass modules here
//     // modules: [Navigation, Pagination],
//     spaceBetween: 15,
//     slidesPerView: 1.6,
//     centeredSlides: true,
//     breakpoints: {
//       996: {
//         slidesPerView: 3,
//         centeredSlides: false,
//       },
//     },
//   });

//   swiper.slideTo(1, false, false);
// };

export const productSlider = () => {
  var slider = document.querySelector(".product-slider");
  if (!slider) {
    return;
  }

  var swiper = new Swiper(".product-slider-thumbs", {
    // loop: true,
    spaceBetween: 20,
    slidesPerView: 4,
    freeMode: true,
    watchSlidesProgress: true,
  });
  var swiper2 = new Swiper(".product-slider", {
    modules: [Thumbs, Navigation],
    // loop: true,
    navigation: {
      nextEl: ".product-slider-next",
      prevEl: ".product-slider-prev",
    },
    thumbs: {
      swiper: swiper,
    },
  });
};
