let cookieNotification = () => {
  let myStorage = window.localStorage,
    cookieWrapper = document.querySelector(".cookie-notification-wrapper"),
    cookieClosers = document.querySelectorAll(".cookie-close"),
    me = {};

  if (!cookieWrapper) {
    return;
  }

  me.addEventListener = function (cookieClose) {
    cookieClose.addEventListener("click", function (e) {
      e.preventDefault();
      if (myStorage) {
        myStorage.setItem("cookie-info", "done");
      }
      if (cookieWrapper) {
        cookieWrapper.classList.remove("opened");
      }
    });
  };

  for (var i = 0; i < cookieClosers.length; i++) {
    me.addEventListener(cookieClosers[i]);
  }

  window.setTimeout(function () {
    if (!myStorage || myStorage.getItem("cookie-info") !== "done") {
      cookieWrapper.classList.add("opened");
    }
  }, 2000);
};

export default cookieNotification;
