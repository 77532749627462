import initPopUp from "./init-pop-up";
import {
  checkIfTimeInputIsCorrect,
  removeValidAndInvalidClasses,
  changeTimeAndDateValuesPopUp,
} from "./input-validation";
import loadPopUp from "./init-pop-up";
import { redirectToThankYouPage } from "./ordering-cards";
import initThankYouPage from "./init-thank-you-page";

const serialize = function (form) {
  // Setup our serialized data
  let serialized = [];
  // Loop through each field in the form
  for (let i = 0; i < form.elements.length; i++) {
    let field = form.elements[i];
    // Don't serialize fields without a name, submits, buttons, file and reset inputs, and disabled fields
    if (
      !field.name ||
      field.disabled ||
      field.type === "file" ||
      field.type === "reset" ||
      field.type === "submit" ||
      field.type === "button"
    )
      continue;
    // If a multi-select, get all selections
    if (field.type === "select-multiple") {
      for (let n = 0; n < field.options.length; n++) {
        if (!field.options[n].selected) continue;
        serialized.push(
          encodeURIComponent(field.name) +
            "=" +
            encodeURIComponent(field.options[n].value)
        );
      }
    }
    // Convert field data to a query string
    else if (
      (field.type !== "checkbox" && field.type !== "radio") ||
      field.checked
    ) {
      serialized.push(
        encodeURIComponent(field.name) + "=" + encodeURIComponent(field.value) //email=n.krstic@futureforward.rs
      );
    }
  }
  return serialized.join("&");
};

const post = function (url, data) {
  return new Promise((resolve, reject) => {
    const req = new XMLHttpRequest();
    req.open("POST", url, true);
    req.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    req.onload = () =>
      req.status === 200
        ? resolve(req.response)
        : reject(Error(req.statusText));
    req.onerror = (e) => reject(Error(`Network Error: ${e}`));
    req.send(data);
  });
};

const formSubmit = () => {
  let forms = document.querySelectorAll("form");

  if (!forms) {
    return;
  }

  forms.forEach((form) => {
    form.addEventListener("submit", (e) => {
      form.classList.add("was-validated");
      if(!form.classList.contains("form-subscribe")){
        const inputFields = form.querySelectorAll("div");
        inputFields.forEach(input =>{
          input.classList.add("was-validated");
          form.querySelector(".invalid-feedback-empty").classList.remove("d-none");
      })
      }
      e.preventDefault();

      if (form.classList.contains("main-form")) {
        if (checkIfTimeInputIsCorrect()) {
          return;
        }
      }

      if (form.classList.contains("needs-validation")) {
        if (!form.checkValidity()) {
          // form.classList.add("was-validated");
          return;
        }
      }

      let data;
      let confirmationPage = document.querySelector("#ConfirmationPage");

      if (confirmationPage) {
        let urlSearch = window.location.search;
        data = urlSearch.substring(1);
      } else {
        data = serialize(form);
      }

      grecaptcha
      .execute("6LcBrK0UAAAAAE6FnhjizLp3vcsk6k8HPn6wcyLI").then( token =>{
        data += "&token=" + token;
        form.className += " loading";
        post(form.getAttribute("action"), data)
        .then((json) => {
          let data = JSON.parse(json);
          if (!data.error) {
            if (confirmationPage) {
              redirectToThankYouPage();
              return;
            }
            changeTimeAndDateValuesPopUp(form);
            loadPopUp(form);
            form.reset();
            removeValidAndInvalidClasses();
          }
          if(data.errorCode === "invalidEmail"){
            const emailInput = form.querySelector('input[type="email"]');
            form.classList.remove("was-validated")
            form.querySelector('.invalid-feedback-email').classList.remove("d-none");
            form.querySelector('.invalid-feedback-empty').classList.add("d-none");
            emailInput.parentElement.classList.remove("was-validated");
            emailInput.classList.remove("is-valid");
            emailInput.classList.add("is-invalid");
          return;
          }

        })
        .catch((err) => {})
        .finally(() => {
          form.className = form.className.replace(" loading", "");
        });
        return false;
      });
    })

    form.addEventListener("input", e => {
      if(!e.target.classList.contains("flatpickr-input")){
        e.target.parentElement.classList.remove("was-validated");
        e.target.classList.remove("is-invalid");
        e.target.classList.remove("is-valid");
      }

      }
    )
  });
};

export { formSubmit, post, serialize };
