const initThankYouPage = () => {
  let chosenTime = sessionStorage.getItem("time");
  let chosenDate = sessionStorage.getItem("date");

  let confirmedPage = document.querySelector("#ConfirmedPage");

  if (!confirmedPage) {
    return;
  }

  let chosenTimeHolder = document.querySelector(".chosen-time");
  let notChosenTimeAndDatePlaceholder = document.querySelector(
    ".not-chosen-time-and-date"
  );
  let atThe = document.querySelector(".at-the");

  if (!chosenDate == "" || !chosenTime == "") {
    chosenTimeHolder.classList.remove("d-none");
    atThe.classList.remove("d-none");
    chosenTimeHolder.innerHTML = [`${chosenTime}` , `${chosenDate}`].join(" ");
    console.log(chosenTimeHolder);
    notChosenTimeAndDatePlaceholder.classList.add("d-none");
  } else {
    atThe.classList.add("d-none");
    notChosenTimeAndDatePlaceholder.classList.remove("d-none");
    chosenTimeHolder.classList.add("d-none");
  }
};

export default initThankYouPage;
