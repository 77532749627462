import alertify from "alertifyjs";

const loadPopUp = (form) => {
  let contactFormText = document.querySelector("#pop-up-text-contact-form");
  let subscribeFormText = document.querySelector("#pop-up-text-subscribe-form");

  if (form.classList.contains("needs-validation")) {
    subscribeFormText.classList.add("d-none");
    contactFormText.classList.remove("d-none");
  } else {
    contactFormText.classList.add("d-none");
    subscribeFormText.classList.remove("d-none");
  }

  const alertifyHeader = document.querySelector(".ajs-header");
  const alertifyBody = document.querySelector(".ajs-body");
  const alertifyContent = document.querySelector(".ajs-content");
  const alertifyFooter = document.querySelector(".ajs-footer");

  alertify.defaults.glossary.title = document.querySelector("#pop-up-logo");
  alertify.defaults.transition = "fade";
  alertify.confirm(document.querySelector("#pop-up-body").innerHTML);
};

export default loadPopUp;
