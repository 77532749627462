import { serialize, formSubmit, post } from "./form-submit";
import initThankYouPage from "./init-thank-you-page";

// Setting Links To The Buttons

const _setDefaultLinkToCards = (btn) => {
  let cardId = btn.id;
  let page = "ordering";

  let orderSearchParams = `${page}?option=${cardId}`;

  btn.setAttribute("href", `/${orderSearchParams}`);
};

const _setDefaultLinkToCardsOnPriceToggle = (btn, priceSwitcher) => {
  let cardId = btn.id;
  let orderSearchParams;
  let page = "ordering";

  if (priceSwitcher.checked) {
    orderSearchParams = `${page}?option=${cardId}`;
  } else {
    orderSearchParams = `${page}?option=${cardId}&year=1`;
  }
  btn.setAttribute("href", `/${orderSearchParams}`);
};

const setOrder = () => {
  let cardButtons = document.querySelectorAll(".card-btn");

  if (!cardButtons) {
    return;
  }
  let priceSwitcher = document.querySelector("#switch");

  cardButtons.forEach((btn) => {
    _setDefaultLinkToCards(btn);
  });

  let priceToggler = document.querySelector(".price-toggle-child");
  if (!priceToggler) {
    return;
  }

  let counter = 0;

  priceToggler.addEventListener("click", (e) => {
    counter++;
    let additionalTextForPriceMonth = document.querySelectorAll(
      ".card-package-duration-month"
    );
    let additionalTextForPriceYear = document.querySelectorAll(
      ".card-package-duration-year"
    );
    let paidAnnuallyText = document.querySelectorAll(".paid-annually");

    if (counter % 2 == 0) {
      for (let i = 0; i < additionalTextForPriceMonth.length; i++) {
        additionalTextForPriceMonth[i].classList.remove("d-none");
        additionalTextForPriceYear[i].classList.add("d-none");
        paidAnnuallyText[i].classList.add("d-none");
      }
    } else {
      for (let i = 0; i < additionalTextForPriceMonth.length; i++) {
        additionalTextForPriceMonth[i].classList.add("d-none");
        additionalTextForPriceYear[i].classList.remove("d-none");
        paidAnnuallyText[i].classList.remove("d-none");
      }
    }
    cardButtons.forEach((btn) => {
      _setDefaultLinkToCardsOnPriceToggle(btn, priceSwitcher);
    });
  });
};

// Step One -> Getting Order

const getOrder = () => {
  let orderForm = document.querySelector("#order-form");
  let confirmationPage = document.querySelector("#ConfirmationPage");

  if (!orderForm && !confirmationPage) {
    return;
  } else {
    let params = new URL(document.location).searchParams;
    let option = params.get("option") || "basic";
    let duration = parseInt(params.get("year"));

    let chosenPackageId = `#card-` + option;

    setCardInformation(chosenPackageId, duration);
  }
};

// Setting

const setCardInformation = (chosenPackageId, duration) => {
  // if (duration == 1) {
  //   cardPrice = document.querySelector(
  //     `${chosenPackageId} .per-year`
  //   ).innerHTML;
  // } else {
  //   cardPrice = document.querySelector(
  //     `${chosenPackageId} .per-month`
  //   ).innerHTML;
  // }

  let cardName = document.querySelector(
    `${chosenPackageId} .card-name`
  ).innerHTML;
  let cardDesc = document.querySelector(
    `${chosenPackageId} .card-description`
  ).innerHTML;
  let cardIncludes = document.querySelectorAll(
    `${chosenPackageId} .card-includes-text`
  );
  let cardOptionsList = document.querySelector(
    `${chosenPackageId} .card-options-list`
  );

  // Template DOM
  let orderedPackagePrice = document.querySelector("#ordered-package-price");
  let orderedPackageName = document.querySelector("#ordered-package-name");
  let orderedPackageDescription = document.querySelector(
    "#ordered-package-description"
  );
  let orderedPackageIncludes = document.querySelector(
    ".ordered-package-includes"
  );
  let orderedPackageIncludesText = document.querySelector(
    ".ordered-package-includes-text"
  );
  let orderedPackageList = document.querySelector(
    ".ordered-package-includes-list"
  );

  // Setting Values

  let params = new URL(document.location).searchParams;
  let orderedPackage = params.get("option") || "basic";

  // orderedPackagePrice.innerHTML = cardPrice;
  orderedPackageName.innerHTML = cardName;
  orderedPackageDescription.innerHTML = cardDesc;
  orderedPackageList.innerHTML = cardOptionsList.innerHTML;

  // set the background and relevant fields to the same colors as the professional card on the previous page on the website.
  if (orderedPackage === "professional") {
    orderedPackagePrice.parentElement.classList.remove("text-secondary");
    orderedPackagePrice.parentElement.classList.add("text-white");

    orderedPackageName.classList.remove("text-primary");
    orderedPackageName.classList.add("text-orange");
    // Catching the closest parent with class "price-item" / price-item class gives gray background and dark text
    orderedPackageDescription
      .closest(".price-item")
      .classList.add("bg-secondary", "text-white");
    orderedPackageDescription
      .closest(".price-item")
      .classList.remove("price-item");

    document
      .querySelector("#ordered-package-duration")
      .classList.remove("text-secondary");
    document
      .querySelector("#ordered-package-duration")
      .classList.add("text-white");
  }
  if (orderedPackage === "enterprise") {
    if (orderedPackagePrice.innerHTML.includes("Prijs")) {
      document.querySelector("h3.h4").innerHTML = "op aanvraag";
    } else {
      document.querySelector("h3.h4").innerHTML = "on request";
    }
  }
};

// Step Two -> Passing card information and order form input values

const takeInputValuesOrderForm = () => {
  let orderForm = document.querySelector("#order-form");
  if (!orderForm) {
    return;
  }

  let orderFormNextBtn = document.querySelector("#order-form-btn");

  orderFormNextBtn.addEventListener("click", () => {
    let orderForm = document.querySelector("#order-form");

    if (!orderForm.checkValidity()) {
      orderForm.classList.add("was-validated");
      document
        .querySelector(".invalid-feedback-empty")
        .classList.remove("d-none");
      return;
    }

    let data = `email=${document.querySelector("#order-form-email").value}`;

    post("php/FakeEmailResponding.php", data)
      .then((json) => {
        let data = JSON.parse(json);
        if (data.error) {
          if (data.errorCode === "invalidEmail") {
            const emailInput = document.querySelector('input[type="email"]');
            document
              .querySelector(".invalid-feedback-email")
              .classList.remove("d-none");
            document
              .querySelector(".invalid-feedback-empty")
              .classList.add("d-none");
            emailInput.parentElement.classList.remove("was-validated");
            emailInput.classList.remove("is-valid");
            emailInput.classList.add("is-invalid");
          }
          return;
        }
        let name = sessionStorage.getItem("name");
        let optionAndDurationSearchParam = optionAndDurationSearchParams();

        let page = "/confirmation";
        let site = window.location.origin;
        let formInputValues = serialize(orderForm);
        let newUrl = `${site}${page}${optionAndDurationSearchParam}&${formInputValues}`;
        window.location.href = newUrl;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  });
};

let optionAndDurationSearchParams = () => {
  let params = new URL(document.location).searchParams;
  let option = params.get("option");
  let duration = parseInt(params.get("year"));
  let optionAndDurationSearchParam;
  if (duration == 1) {
    optionAndDurationSearchParam = `?option=${option}&year=1`;
  } else {
    optionAndDurationSearchParam = `?option=${option}`;
  }
  return optionAndDurationSearchParam;
};

const setInputValuesFromOrderForm = () => {
  let params = new URL(document.location).searchParams;
  let name = params.get("name") || "";
  let email = params.get("email") || "";
  let phone = params.get("phone") || "";
  let message = params.get("message") || "";
  let date = params.get("date") || "";
  let time = params.get("time") || "";

  let confirmationPageName = document.querySelector(".confirmation-page-name");
  let confirmationPageEmail = document.querySelector(
    ".confirmation-page-email"
  );
  let confirmationPagePhone = document.querySelector(
    ".confirmation-page-phone"
  );
  let confirmationPageMessage = document.querySelector(
    ".confirmation-page-message"
  );
  let confirmationPageDateAndTime = document.querySelector(
    ".confirmation-page-date-and-time"
  );

  confirmationPageName.innerHTML = name;
  confirmationPageEmail.innerHTML = email;
  confirmationPagePhone.innerHTML = phone;
  confirmationPageMessage.innerHTML = message;
  confirmationPageDateAndTime.innerHTML = `${date} ${time}`;
};

// Setting Link on Back Button

const setBackButtonLink = () => {
  let btn = document.querySelector(".confirmation-page-back-btn");
  var page = "/ordering";

  orderSearchParams = `${page}${window.location.search}`;

  btn.setAttribute("href", `${window.location.origin}${orderSearchParams}`);
};

window.addEventListener("load", (event) => {
  let windowLocationPath = window.location.pathname;

  if (windowLocationPath.includes("confirmation")) {
    setInputValuesFromOrderForm();
    setBackButtonLink();
    putTimeAndDateValuesLocStorage();
  }

  if (windowLocationPath.includes("confirmed")) {
    initThankYouPage();
  }

  if (windowLocationPath.includes("ordering")) {
    let params = new URL(document.location).searchParams;
    let name = params.get("name") || "";
    let email = params.get("email") || "";
    let phone = params.get("phone") || "";
    let message = params.get("message") || "";
    let date = params.get("date") || "";
    let time = params.get("time") || "";

    let orderFormInputName = document.querySelector("#order-form-name");
    let orderFormInputEmail = document.querySelector("#order-form-email");
    let orderFormInputPhone = document.querySelector("#order-form-phone");
    let orderFormInputMessage = document.querySelector("#order-form-message");
    let orderFormInputDate = document.querySelector("#order-form-date");
    let orderFormInputTime = document.querySelector("#order-form-time");

    if (name != "") {
      orderFormInputName.value = name;
      orderFormInputEmail.value = email;
      orderFormInputPhone.value = phone;
      orderFormInputMessage.value = message;
      orderFormInputDate.value = date;
      orderFormInputTime.value = time;
    }
  }
});

const putTimeAndDateValuesLocStorage = () => {
  let params = new URL(document.location).searchParams;
  let time = params.get("time");
  let date = params.get("date");

  sessionStorage.setItem("time", time);
  sessionStorage.setItem("date", date);
};

// Step Three -> Redirect to Thank You page

const redirectToThankYouPage = () => {
  let site = window.location.host;
  let page = "confirmed";
  window.location = `/${page}`;
};

export { setOrder, getOrder, takeInputValuesOrderForm, redirectToThankYouPage };
